// Sale model

import saleLine from "./ModelSaleLine";
import tools from "tools/dates";
// import Shipment from "./Shipment";
// import Invoice from "./Invoice";

let today = tools.dateToday();

const getFilters = () => {
  return {
    today: [
      [
        "OR",
        ["sale_date", "=", `${today}`],
        ["create_date", ">=", `${today} 00:00:00`],
      ],
    ],
    pending: [
      "OR",
      ["state", "in", ["draft", "quotation"]],
      ["shipments.state", "not in", ["done", "cancelled"]],
    ],
    quotation: [["state", "=", "quotation"]],
    processing: [["state", "=", "processing"]],
    draft: [["state", "=", "draft"]],
  };
};

const stateColors = {
  none: "slate",
  quotation: "amber",
  processing: "sky",
  done: "lime",
  canceled: "rose",
};

const getView = (config) => {
  let DictCtxView = {
    selectable: false, // options: multi - one - false
    activeSearch: true,
    filters: getFilters,
    pagination: [],
    domain: [],
    tags: {
      state: stateColors,
    },
    limit: 50,
    form_action: ["open"],
    table_action: ["open"],
    model: "sale.sale",
    webfields: {
      number: {
        // type: "char",
        type: "qr",
        readOnly: true,
        searchable: true,
        // Component: "qr",
      },
      party: {
        type: "many2one",
        model: "party.party",
        required: true,
        readOnly: true,
        searchable: true,
      },
      sale_date: {
        type: "date",
        readOnly: true,
        searchable: true,
      },
      shipment_date: {
        type: "date",
        required: false,
        readOnly: true,
      },
      shipment_address: {
        type: "many2one",
        model: "party.address",
        readOnly: true,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        readOnly: true,
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        tags: stateColors,
      },
      reference: {
        type: "char",
        readOnly: true,
        searchable: true,
      },
      invoice_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      invoices: {
        type: "button_custom_modal",
        readOnly: true,
        color: "blue",
        search: true,
        desired_action: "count",
        // Component: Invoice,
      },
      shipments: {
        type: "button_custom_modal",
        readOnly: true,
        color: "blue",
        search: true,
        desired_action: "count",
        // Component: Shipment,
      },
      // shipment_returns: {
      //   type: "button_custom_modal",
      //   readOnly: true,
      //   color: "blue",
      //   search: true,
      //   desired_action: "count",
      //   Component: Shipment,
      // },
      shipment_state: {
        type: "char",
        readOnly: true,
        translate: true,
      },
      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: saleLine.ctxView(config),
        required: true,
        readOnly: { state: ["quotation"] },
      },
      untaxed_amount: {
        type: "numeric",
        readOnly: true,
      },
      comment: { type: "text", readOnly: true },
      description: { type: "char", readOnly: true },
    },
    webtree: [
      { name: "number", width: "10%" },
      { name: "sale_date", width: "10%" },
      { name: "party", width: "15%" },
      // { name: "agent", width: "15%" },
      { name: "reference", width: "10" },
      { name: "untaxed_amount", width: "10%" },
      { name: "state", width: "5%", widget: "circle" },
      // { name: "invoices", width: "5%" },
      { name: "invoice_state", width: "10%" },
      // { name: "shipments", width: "5%" },
      // { name: "shipment_returns", width: "5%" },
      // { name: "shipment_state", width: "10%" },
    ],
    webform: [
      // { name: "agent" },
      {
        id: "infoSale",
        grid: [{ name: "shipment_date" }],
        size: [2, 2],
        span: "col-span-1",
      },
      { name: "party" },
      { name: "shipment_address" },
      { name: "reference" },
      { name: "description" },
      { name: "lines", component: "modal" },
      { name: "comment" },
      { name: "untaxed_amount" },
      { name: "state" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
