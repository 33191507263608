const hotels = {
  HOTELTERRA: {
    colorPrimary: "!bg-[#0e2d1b]",
    hoverColorPrimary: "bg-[#0e2d1b]",
    colorTextPrimary: "text-[#ff8D6D]",
    colorSecundary: "bg-[#ff8D6D]",
    privacyPolicies: "https://terrabiohotel.com/politica-de-privacidad/",
    dataProtection: "https://terrabiohotel.com/proteccion-de-datos/",
    callToActions: "text-white",
    colorIcon: "#7bbb4b",
    url: "https://terrabiohotel.com/",
  },
  HOTELKOKKOBEACH: {
    colorPrimary: "!bg-[#0a5b4c]",
    hoverColorPrimary: "bg-[#0a5b4c]",
    colorTextPrimary: "text-[#ff8D6D]",
    colorSecundary: "bg-[#ff8D6D]",
    privacyPolicies: "https://terrabiohotel.com/politica-de-privacidad/",
    dataProtection: "https://terrabiohotel.com/proteccion-de-datos/",
    callToActions: "text-white",
    colorIcon: "#7bbb4b",
    url: "#",
    sizeLogo: "md:h-28",
  },
  HOTELWAIRA: {
    colorPrimary: "!bg-[#244725]",
    hoverColorPrimary: "bg-[#2a3b2b]",
    colorTextPrimary: "text-[#009f04]",
    colorSecundary: "bg-[#9cc25b]",
    privacyPolicies: "#",
    dataProtection: "#",
    callToActions: "text-white",
    colorIcon: "#9cc25b",
    url: "https://wairahotel.com.co/",
    sizeLogo: "md:h-32",
  },
  ABORIGENES: {
    colorPrimary: "!bg-[#000000]",
    hoverColorPrimary: "bg-[#000000]",
    colorTextPrimary: "text-[#c0622f]",
    colorSecundary: "bg-[#c0622f]",
    privacyPolicies: "",
    dataProtection: "",
    callToActions: "text-white",
    colorIcon: "#c0622f",
    url: "#",
    sizeLogo: "md:h-24",
  },
  ARISHA: {
    colorPrimary: "bg-[#181818]",
    hoverColorPrimary: "bg-[#181818]",
    colorTextPrimary: "white",
    colorSecundary: "bg-[#a14a25]",
    privacyPolicies: "#",
    dataProtection: "#",
    grayScale: true,
    callToActions: "text-white",
    url: "https://arishahotel.com/",
  },
};

export default hotels;
