//model product

const getView = () => {
  let DictCtxView = {
    selectable: "multi",
    activeSearch: true,
    filters: null,
    limit: 200,
    model: "product.product",
    form_action: [],
    table_action: [],
    webfields: {
      default_uom: { type: "many2one", model: "product.uom" },
      name: { type: "char", readOnly: true, searchable: true },
      code: { type: "char", readOnly: true, searchable: true },
      template: {
        type: "many2one",
        model: "product.template",
        readOnly: true,
        searchable: true,
      },
      description: { type: "char", readOnly: true, searchable: true },
    },
    webtree: [
      // { name: "code", width: "10%" },
      { name: "name", width: "40%" },
      // { name: "description", width: "20%" },
      // { name: "default_uom", width: "10%" },
    ],
    webform: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
